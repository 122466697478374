import classnames from "classnames";
import FreeToAir from "components/Icons/FreeToAir";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import { applyBlur } from "helpers/utility";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

export default class ThumbnailCoverLibrary extends Component {
  state = {
    hover: false,
    item: null,
  };
  async componentDidMount() {
    if (consts.displayVodData) {
      const { asset } = this.props;
      /* let metadata;
      if (asset instanceof TucanoModels.Asset && asset.getVodData()) {
        metadata = asset.getVodData();
      }*/
      if (asset && asset.flyImage) {
        await this.setState({ item: asset.flyImage });
      }
    }
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const {
      asset,
      rootClassName,
      grid,
      onlyPlay,
      size,
      large,
      fta,
      isConnected,
      imageType,
      isLocked,
      profile,
    } = this.props;
    const { hover, item } = this.state;
    /*let catalog;
    if (asset instanceof TucanoModels.TVShow) {
      catalog = asset.getCatalogue();
    }
    if (asset.catalogs) {
      catalog = asset.catalogs;
    }*/
    let url, type, src;

    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }

    if (asset.getType() !== "EPGBroadcast" && !asset.isCatchupAsset) {
      if (asset.isSerie || asset.isAssetTVShow) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      } else if (asset.isAssetTVShowEpisode === true) {
        url = consts.routes.player.url.replace(":assetId", asset.getId());
      } else if (asset.isCategory) {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }

    if (asset.getType() === "channel_logo") {
      url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
    }

    if (asset.getType() === "EPGBroadcast") {
      type = "epgEvent";
      if (asset.isLive()) {
        url = consts.routes.playerlive.url.replace(
          ":channelId",
          asset.getChannelId()
        );
      } else {
        url = consts.routes.epgAsset.url.replace(":id", asset.getEpgId());
      }
    }

    if (asset.getType() === "link") {
      url = getRealSlug(asset.getUrl());
    }
    if (imageType) {
      src = `${consts.image_hostname}${
        routes.imgdata
      }?type=${imageType}&objectId=${asset.getId()}&format_w=${
        asset instanceof TucanoModels.Channel
          ? size.square.width
          : size.cover.width
      }&format_h=${
        asset instanceof TucanoModels.Channel
          ? size.square.height
          : size.cover.height
      }`;
    }

    return (
      <LinkDuo
        to={url}
        className={classnames(rootClassName, style.container, {
          [style.grid]: grid,
          [style.large]: large,
          [style.container_hover]: hover,
        })}
        onMouseEnter={() =>
          this.setState({ hover: this.props.showInfosOverlay })
        }
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          minWidth: !large ? size?.coverSmall?.width : size?.cover?.width,
          minHeight: !large ? size?.coverSmall?.height : size?.cover?.height,
        }}
      >
        <div className={style.content}>
          <Image
            src={
              src
                ? src
                : asset.getImage(consts.image_hostname, {
                    width: asset instanceof TucanoModels.Channel ? 266 : 764,
                    height: asset instanceof TucanoModels.Channel ? 266 : 430,
                    languageId: getFromLocal("languageId"),
                  })
            }
            width={!large ? size.coverSmall?.width : size.cover.width}
            height={!large ? size.coverSmall?.height : size.cover.height}
            ratio={ImageRatio.COVER}
            placeholder={PlaceholderType.PRIMARY}
            blur={applyBlur(asset, profile)}
          />
          {type === "epgEvent" && (
            <Image
              rootClassName={style.channel}
              height={40}
              width={40}
              effect={"opacity"}
              src={`${consts.image_hostname}${routes.imgdata}?type=${
                consts.imageTypes.tvChannel
              }&objectId=${asset.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                "languageId"
              )}`}
              placeholder={PlaceholderType.TRANSPARENT}
            />
          )}
          {type !== "epgEvent" &&
            !!asset.seekTime &&
            (large ? (
              <ProgressBar
                position={asset.getProgress()}
                rootClassName={style.progressBarForXL}
              />
            ) : (
              <ProgressBar
                position={asset.getProgress()}
                rootClassName={style.progressBar}
              />
            ))}
          {type === "epgEvent" &&
            asset.isLive() &&
            asset.getEpgProgress() &&
            (large ? (
              <ProgressBar
                position={asset.getEpgProgress()}
                rootClassName={style.progressBarForXL}
              />
            ) : (
              <ProgressBar
                position={asset.getEpgProgress()}
                rootClassName={style.progressBar}
              />
            ))}
          {item && (
            <div className={classnames(style.VODLogo)}>
              <LogoVod
                className={classnames(style.vodData)}
                type={item}
                size={40}
              />
            </div>
          )}
          {fta && !isConnected && (
            <div className={style.ftaIconContainer}>
              <FreeToAir className={style.fta} />
            </div>
          )}
        </div>
        <ThumbnailInfos
          show={hover}
          model={asset}
          onlyPlay={onlyPlay}
          subscriptionModal={this.subscriptionModal.bind(this)}
          isLocked={isLocked}
        />
      </LinkDuo>
    );
  }
}

ThumbnailCoverLibrary.defaultProps = {
  asset: {},
  rootClassName: "",
  size: {},
  showInfosOverlay: true,
  onlyPlay: false,
  large: false,
  grid: false,
  isLocked: false,
};

ThumbnailCoverLibrary.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
  showInfosOverlay: PropTypes.bool,
  onlyPlay: PropTypes.bool,
  large: PropTypes.bool,
  grid: PropTypes.bool,
};
